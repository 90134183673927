
.mainContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  /* border: 1px solid red; */
}

.topImageContainer {
  display: flex;
  flex-direction: column;
  height: 20%;
  background-color: white;
}

.companyLogoSetup {
  width: 20%;
  height: 50%;
  margin-left: 1rem;
  margin-top: 1rem;
  margin-bottom: 2rem;
  object-fit: scale-down;
  justify-content: center;
  align-items: flex-start;
}

.dashboardNameSetup {
  color: black;
  margin-left: 8rem;
  font-size: 30px;
  font-weight: 700;
  text-align: left;
}

.mainContainerFlexView {
  display: flex;
  flex-direction: row;
  /* width: 80%; */
  /* height: 250px; */
  border: 1px solid black;
  background-color: white;
  margin-top: 2rem;
  margin-left: 8rem;
  margin-right: 8rem;
}

.liftDetailsFlex {
  flex-grow: 1;
  flex-direction: row;
  padding: 2rem;
  margin: auto;
  max-width: 50%;
  height: 200px;
  background-color: white;
  margin-left: 0%;
}

.canMessageFlex {
  flex-grow: 1;
  flex-direction: row;
  padding: 2rem;
  margin: auto;
  max-width: 50%;
  background-color: white;
  margin-right: 0%;
}

.mapViewContainer {
  width: 200px;
  height: 180px;
  margin: auto;
  /* display: block; */
  max-width: 100%;
  max-height: 100%;
}

.canMessageContentFlex {
  flex-grow: 1;
  flex-direction: row;
  padding: 2rem;
  margin: auto;
  max-width: 100%;
  height: 120px;
  margin-right: 0%;
  margin-top: 0rem;
  margin-bottom: 2%;
  margin-left: 0rem;
  background-color: rgb(238, 238, 238);
}

/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */

/* .App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
