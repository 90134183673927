/* You can add global styles to this file, and also import other style files */

@import 'assets/variables.scss';

body {
   // margin: 25px;
    font-family: Arial, Helvetica, sans-serif;

}

.textboxSeperator{
    border-color: #4d4d4f;
    opacity: .5;
    margin: 0;
    width: 0;
    height: 16px;
    border-width: 0 0 0 1px;
    border-style: solid;
}
.flexContainer{
    display: flex; 
    justify-content: space-between;
}
.flexContainer-detail{
    @extend .flexContainer;
    width: 100%; 
    flex-wrap: wrap;
}
.flexContainer-detail > * {
    flex-basis: 28%;
    flex-grow: 1;
    flex-shrink: 1;
    align-self: flex-start;  

}
.flexContainer-detail-static > * {
    flex-shrink: 1
}
@mixin  modularDivScale {
    height: auto !important; 
    overflow:auto;
}
.modularDivScale{
@include modularDivScale;
}
.dashboard-card{
    @extend .modularDivScale;
    padding:0px !important;
}
.dashboard-card-header{
    @extend .modularDivScale;
    position:relative;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap
    
}

.dashboard-card-header-right-panel{
    // @extend .modularDivScale;
    // position:relative;
    display: flex;
    justify-content: space-between;
    // flex-wrap: wrap
    flex-direction: row;
}

.dashboard-card-header > div:first-of-type{
     margin-left:15px; 
     margin-top:15px;
}
.dashboard-card-header > div:first-of-type > span{
    text-align: left;
     display:block;
}
.dashboard-card-header > div:nth-of-type(2){
    margin-left:15px;
    margin-top:15px;
    margin-right:15px
}
.dashboard-header {
    margin-top: 1px;
    vertical-align: bottom;
    text-align: center;
    font: 2em arial;
    width: 100%;
    height: 4em;
    // 1.75em;
    // border-bottom: 1px solid black;
    // height: auto;
    overflow:auto;
    background-color: lightblue;
    margin-bottom: .5em;
    border-radius: .33em;
    box-shadow: 0 1px 2px 0 rgba(0,0,0,0.1);
}
.detail-card-header{
        @extend .modularDivScale;
        border-bottom: 1px black solid;
        border-bottom-right-radius: 0.33em;
        border-bottom-left-radius: 0.33em;
         color:white;
         background-color: grey;
    
}
.detail-card-header > span{
    float: left;
    margin-top: .75em;
    margin-bottom: .75em;
    margin-left: .5em;
    font-weight: bold;
}

.detail-card-header-inline{
    @extend .modularDivScale;
    border-bottom: 1px black solid;
     color:white;
     background-color: grey;

}
.detail-card-header-inline > span{
float: left;
margin-top: .75em;
margin-bottom: .75em;
margin-left: .5em;
font-weight: bold;
}
.dashboard-header button{
    float: left;
}

.main-layout{
    width:100%;
}
.dashboard{
    display: flex; 
    justify-content: space-between;     
    flex-wrap: wrap;
    background-color: #F2F2F2
}

.dashboardAdminPanel {
    display: flex; 
    justify-content: space-between;     
    flex-wrap: wrap;
}

.dashboardAdmin-top-panel {
    margin-top: 20px;
    margin-left: 40px;
    font-size: 20px;
    font-weight: bold;
}

.dashboard-bottom-panel {
    display: flex;
    flex-direction: row;
    margin-top: 50px;
    margin-left: 40px;
    font-size: 20px;
    font-weight: bold;
    justify-content: space-between
}

.dashboardData-panel {
    display: flex;
    margin-top: 10px;
    margin-left: 40px;
    font-size: 20px;
    font-weight: bold;
    width: 100%;
    margin-right: 50px;
}

.dashboard-filter-elements {
    display: flex;
    flex-direction: row;
    font-size: 20px;
    font-weight: bold;
    justify-content: space-between
}

.dashboardAdmin-bottom-panel {
    flex-grow: 1;
    flex-shrink: 1; 
}

.dashboardExpandButton {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
}

.dashboard-left-panel{
    flex-grow: 0;
    flex-shrink: 0;
    width: 24%;
}

.dashboard-left-panel-collapse{
    flex-grow: 0;
    flex-shrink: 0;
    width: 3%;
}

.dashboard-right-panel{
    // flex-basis:75%; 
    flex-grow: 1;
    flex-shrink: 0;
    width: 76%;
}

.fleetComparisionPanel {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
}

.fleetComparisonBarGraph {
    width: 50%;
    z-index: 0;
}

.fleetComparisonPieChart {
    width: 50%;
    margin-left: 20px;
    margin-top: 80px;
    z-index: 0;
}

.fleetComparisionTopDropDown {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 80%;
    margin-bottom: 20px;
    margin-left: 10px;
    margin-top: 20px;
    justify-content: space-between;
}

.fleetComparisionReportLoading {
    display: flex;
    align-items: center;
    justify-content: center;
    /* margin-top: 60px; */
    /* margin-left: 100px; */
    width: 100%;
    height: 100%;
}

.fleetComparisionTopTitle {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-bottom: 20px;
}

.box {
    height: 20px;
    width: 20px;
    margin-bottom: 15px;
    border: 1px solid black;
  }
  
.userTopPanelElements {
    display: flex;
    align-self: flex-end;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 15px;
    width: 100%;
}

.userDropDown {
    display: flex;
    align-self: flex-end;
    flex-direction: row;
    justify-content: flex-start;
    margin-top: 15px;
}

.customerTopPanelElements {
    display: flex;
    align-self: flex-end;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 15px;
    width: 100%;
}

.customerDropDown {
    display: flex;
    align-self: flex-end;
    flex-direction: row;
    justify-content: flex-start;
    margin-top: 15px;
}

.customerModal {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-top: 5px;
    width: 400px;
    height: 150px;
    
}

.locationModal {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-top: 5px;
    width: 400px;
    height: 400px;
    
}

.duoMapPopupCard {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 220px;
    height: 150px;
}


.duoMapPopupCardForLoading {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 220px;
    height: 150px;
}

.duoMapPopupCardHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 220px;
    height: 20px;
}

.duoMapCardImageSection {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 200px;
    height: 20px;
    margin-top: 20px;
    margin-left: 10px;
    margin-right: 10px;
}

.duoRightCardImageSection {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 20px;
    margin-top: 20px;
    margin-left: 10px;
    margin-right: 10px;
}

.duoMapCardImgAndLabel {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 5px;
}

.duoMapCardLastUpdated {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-top: 30px;
}

.userModal {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-top: 5px;
    width: 600px;
    height: 100%;
}

.liftModalTopSelection {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 5px;
    align-items: center;
    width: 100%;
}

.userModalTopSelection {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 5px;
    margin-bottom: 20px;
    width: 100%;
}

.userModalTopUserRole {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-top: 5px;
    align-items: center;
}

.userModalFloatingLabelsSection {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 5px;
    align-items: flex-start;
    width: 100%;
}

.k-animation-container {
    z-index: 10003 // set the desired value based on the application requirements
}

.userFLoatingLabel {
    display: flex;
    flex-direction: row;
    // justify-content: space-between;
    margin-top: 5px;
    align-items: center;
    width: 100%;
}

.statusModal {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-top: 5px;
    width: 200px;
    height: 100px; 
}

.modalForStatusChange {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-top: 5px;
    width: 280px;
    height: 180px; 
}

.statusModalActiveCheckBox {
    display: flex;
    flex-direction: column;
    margin-top: 5px;
    width: 100%;
    align-items: center;
}

.statusModalSaveButton {
    display: flex;
    margin-top: 40px;
    width: 100%;
    justify-content: center;
}

.customerModalTextBox {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
    margin-top: 5px;
}

.customerModalBottomButton {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-top: 5px;
}

/*The Background grey color*/
.greyColorBackground {
    background: #F4F4F4;
}

@mixin duolift-device-card {
        /* Add shadows to create the "card" effect */
    margin: 1.0em .25em 0.5em 0.25em;
    padding: 0.5em 0.5em 0.5em 0.5em;
    text-align: center;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.2s;
    // width: 15em;
    height: 10em;
    // border: .1px solid black;
    // border-radius: .33em;
}
.duolift-device-card{
    @include duolift-device-card;
}



.duolift-device-card-selected {
    @extend .duolift-device-card;
    background-color: lightblue;
    border: 3px solid #00B0F0;
    width: 90%;
}

.duolift-device-card-notselected {
    @extend .duolift-device-card;
    background-color: white;
    width: 90%;
}

.duolift-device-card:hover {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}

.duolift-device-list {
    margin: 0em 0em 0em 0em;
    float: left;
    width: 100%;
}
.duoLiftList{
    max-height: 100vh;
    overflow-y: auto;
    // -ms-overflow-style: none;  /* IE and Edge */
    // scrollbar-width: none;  /* Firefox */
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    margin-right: 25px;
    width: 100%;
}

// .duoLiftList::-webkit-scrollbar {
//     display: none;
//   }
.duoLiftlistTopSelection {
    display: flex;
    // justify-content: flex-start;
    // align-items: center;
    flex-grow: 0;
    flex-direction: column;
    margin-bottom: 20px;
    width: 100%;
}

.duoLiftTitleContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.duoLiftDropDownContainer {
    display: flex;
    flex-direction: column;
    margin-left: 20px;
    margin-right: 40px;
    justify-content: space-between;
    margin-top: 10px;
    width: 80%;
}

.liftHeadingFont {
    font-size: 28px;
    font-weight: bold;
}

.duoLiftList > li:first-of-type > div{
    margin: 0em .25em 0.5em 0.0em;

}
.header-fullscreen-menu{
    float: right;
}
.header-fullscreen-menu > button{
    vertical-align: top;
}
.header-icon-menu{
    display: table;
    float: left;
    margin-left: .5em;
    margin-top: .6em;
  // margin-left: 25px
}
.header-icon-menu > img{
    width: 10em;
    //margin-left: 25%;

}
.header-icon-menu > a{
    display: table-cell;
    vertical-align: middle;
    text-decoration: none;
    color:white
}
.header-icon-menu > a:hover{
    color:white
}
.header-icon-menu > a:visited{
    color:white
}
.header-icon-menu > a > span {
    font-size:24px;
}
.header-icon-menu > span{
    display: block;
     font-size: 14px;
     margin-bottom: 5px
}

.duolift-device-detail-card:nth-child(2){
 margin-right: 0px;
}
.duolift-device-detail-card:nth-child(3){
    margin-right: 0px;
   }
.duolift-device-service-detail-card{
    flex-grow: 1;
    flex-shrink: 1;
    align-self: flex-start;
    position: relative;

}
.heading{
    color:#303030;
    font-size : 18px !important;
    font-weight: 600 !important;
}

@mixin duolift-device-detail-card {
    @include duolift-device-card;

    @include modularDivScale;
    margin: 1.0em 1.5em 0.5em 0.0em;

    padding:0px;
    min-height: 12em;
    position: relative;
}
.duolift-device-detail-card{
   @include duolift-device-detail-card;
}
.duolift-device-detail-card-body{
    padding: 0.5em 0.5em 0.5em 0.5em;
}

.duolift-device-detail-card h4{
    text-align: left;
    margin-top: 0;
}
.duolift-device-detail-card h4, h5{
    text-align: left;
    margin-top: 0;
}
.alert{
    display: flex; 
    justify-content: space-between;     
    flex-wrap: wrap;
}
.alert-left-panel{
    flex-grow: 1;
    flex-shrink: 1;
    margin-right:25px;
}
.alert-right-panel{
    flex-basis:60%;   
    flex-grow: 1;
    flex-shrink: 1;
    // margin-bottom:2em;
    margin-left: 25px;
}
.hideCard{
    // padding-top: 1em;  
    position: absolute;
    bottom: 5px;
    right: 5px;
}
.hideCard span{
    font-size: 24px;

}
.floatingLabel{
    display: inline-block; 
    width: 100%;
}
.floatingLabel label{
    margin-left: 25px;
}
.LoginError .k-textbox{
    border: 1px solid red;
}
.login-display-error{
    margin-block-end:0px;
     color:red
}
.login-form > div{
    display: inline-block;
    width: 20em;
}

.login-form a{
    text-align: right;
    font-size: small; 
    margin-block-end: 1.5em;
    margin-block-start: .5em;
     display: block;
     text-decoration: underline; 
     float:right;

}

.login-form a:hover{
    font-weight: 300;

}

.login-form a:active{
    color:blue;

}

.login-form > button{
    width: 5em;    
    display: block;
    margin-left: 45%;
}

.resetOr{
    width: 100%; 
    text-align: center; 
    border-bottom: 1px solid #000; 
    line-height: 0.1em;
    margin: 40px 0 20px; 
    font-weight: 100;;
}

.resetOr > span{
    background:#fff; 
    padding:0 10px; 

}

.reset-instructions{
    display: flex;
    justify-content:space-between;
}

.reset-instructions > p{
    width: 10em;
    flex-grow: 1;
}

.reset-instructions > form{
    width: 15em;
       flex-grow: 1;
}

.dividing-line{
    border-left: 2px solid black;
    margin-left: 1em;
    margin-right: 1em;
}

.serviceIndicatorImage {
    height: 50px;
    width: 50px;
}

.serviceIndicatorCardImage {
    height: 30px;
    width: 30px;
    margin-left: 10px;
}

.liftCustomerNameFlex {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    margin-left: 15px;
    margin-top: 15px;
    margin-bottom: 10px;
    opacity: 0.5;
}

.liftCustomerNameFlexForMap {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    margin-left: 0px;
    margin-top: 0px;
    margin-bottom: 0px;
}

.liftLocationFlexForMap {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    margin-left: 0px;
    margin-right: 20px;
    margin-top: 0px;
    margin-bottom: 0px;
    font-weight: bold;
    text-transform: uppercase;
}

.liftLocationFlexForRightPanel {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    margin-left: 15px;
    margin-right: 20px;
    margin-top: 0px;
    margin-bottom: 0px;
    text-transform: uppercase;
}

.serviceIndicatorFlex {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-grow: 1;
    flex-direction: row;
    margin-right: 20px;
    margin-left: 15px;
    margin-top: 15px;
    margin-bottom: 15px;
    border-radius: 0.33em;
    width: 90%;
    height: 40px;
}

.serviceIndicatorFlexForPanel {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-grow: 1;
    flex-direction: row;
    margin-right: 20px;
    margin-left: 15px;
    margin-top: 15px;
    margin-bottom: 15px;
    border-radius: 0.33em;
    width: 90%;
    height: 40px;
}

.serviceIndicatorText {
    padding: 1em;
    text-align: left;
    font-size: medium;
}


.cardRow {
    display: block;  
    text-align: left;
}
.loginSubmitButton{
    width: 5em;
    display: inline-block;
    margin-top: 2em;
}
.loginMenu{
    display: flex;
    align-content: stretch;
    justify-content: center;
    width: 33%;
    padding: 5% 0 0;
    z-index: 10000;
    margin: auto;
}
.login-header > h3, > h4{
    font-weight: 100;
}
.card-icon > p:first-of-type{
    font-size: small;
}
.card-icon > img{
    width:80%;
}
.card-icon-container{
    margin-top:15px;
    display: flex;
    justify-content: space-evenly;
     flex-wrap: wrap;
}
.flexContainer:not(:last-child) > .alert-container{
    border-bottom: 1px solid black;
    margin-bottom: .5em;
}

.alert-container{
    text-align: left; 
    width:90%; 
     display:inline-block;
}
.alert-container > span{
    margin-block-end: .5em;
    display: block;
}
.alert-date{
    margin-top: 5px;
    text-align: right;
    margin-block-end: 0.25em;
     font-style: italic;
    font-size: 14px
}
.alert-gis{
    display:flex;
     justify-content:space-between;
     flex-wrap:wrap
}
.alert-no-alerts{
    @extend .alert-right-panel;
    margin:auto;
    margin-top: 50px;

}
.service-no-alerts{
    margin-top:15px;
     margin-bottom: 25px;
      display:block 
}
.alert-counter{
    font-size: 60px;
    text-align:left;
    display: block;
}
.alert-manual-override{
    font-size: small;
}

.stopBiometricsTopPanel {
    display:flex;
    flex-direction: row;
    justify-content:space-evenly; 
    background-color: lightgray;
    height: 200px;
    width: 100%;
}

.stopBioMetricsTopElements {
    display:flex;
    justify-content: space-between;
    background-color: lightgray;
    flex-direction: column;
    padding-left: 10px;
    padding-top: 10px;
    margin-top: 20px;
    padding-right: 10px;
    height: 60%;
}

.stopBioMetricsTopBoxes {
    display:flex;
    background-color: white;
    padding-left: 10px;
    padding-top: 10px;
    padding-right: 10px;
    border: solid;
    justify-content: center;
    height: 50%; 
}

.connectedIcon{
    height: 15px;
    width: 15px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
    border: #000;
    box-sizing: 1px;
    border-style: solid;
    border-width: .25px;
}
.mapMarker{

        position: absolute;
        top: 50%;
        left: 50%;
        width: 18px;
        height: 18px;
        background-color: #000;
        border: 2px solid #fff;
        border-radius: 100%;
        user-select: none;
        transform: translate(-50%, -50%);
        &:hover {
          z-index: 1;
        }
}
.k-dialog-titlebar {
    background-color: grey;
    font-weight: bold;
  }
.dialog-container{
    width:60%;
    margin-left:20%
}
.k-badge-selector{
    
    transform:translate(-100%, 40%) !important;
    cursor: pointer
}

/* width */
.duoLiftList::-webkit-scrollbar {
    width: 10px;
    
  }
  
  /* Handle */
  .duoLiftList::-webkit-scrollbar-thumb {
    // background: #888; 
    background: lightblue;
    border-radius: 8px;
    // border: solid 3px transparent;

    background-clip: padding-box; 
  }
  
  /* Handle on hover */
  .duoLiftList::-webkit-scrollbar-thumb:hover {
    background: rgb(90, 121, 161);
  }

  .mapItemsView {
    display: flex;
    width: 68%;  
    // margin-top: 5em;
    // margin-left: 1em;
    flex-grow: 0;
    max-width: 68%;
    // justify-content: space-between;
    margin-left: 190px;
  }

  .fa-triangle-exclamation {
    color: red;
  }

  .fa-temperature-half {
    color: red; 
  }

  .fa-battery-full {
      color: green;
  }

  .fa-wifi {
    color: green; 
  }

  .fa-wifi-slash {
    color: gray;  
  }

  .fa-wrench {
      color: white;
  }

  .fa-battery-half {
    color: #FB9700;
  }

  .MuiTextField-root {
    color: white;
  }

  .fa-battery-three-quarters {
    color: #FB9700;
  }

  .fa-battery-quarter {
    color: red; 
  }

  .fa-battery-empty {
    color: red; 
  }

  .MuiFormControl-root {
      border-width: 0px;
      box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  }

  .MuiOutlinedInput-notchedOutline {
      border-width: 0px;
  }

  .stopItemsView {
    display: flex;
    margin-top: 30px;
    flex-direction: row;
    justify-content: space-between;
    width: 40%;   
  }

  .stopDropDownView {
    display: flex;
    align-self: flex-end;
    flex-direction: column;
    justify-content: space-between;
  }

  .stopDateItemsView {
    display: flex;
    margin-top: 5px;
    flex-direction: column;
    justify-content: space-between;
    // width: 40%;    
  }

  .mapAlertsSelectionView {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-bottom: 1em;
    margin-top: -12px;
  }

  .mapAlertCardView {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #00B0F0;
    color: white;
    margin-right: 5px;
    border: 2px solid black;
    font-size: 1px;
  }

  .mapAlertsCheckboxes {
    display: flex;
    flex-direction: row;
    // justify-content: space-between;
    margin-top: 0.5em;
    margin-bottom: 1em;
    width: 100%;
    // margin-left: 0.5em;
  }

  .css-i44wyl {
      background-color: #00B0F0
  }

  .MuiFormLabel-root-MuiInputLabel-root{
    color: white
  }

  .datePicker {
    color: white;
    width: 100px
  }
  .datesView {
    display: flex;
    flex-direction: column;
    // justify-content: start;
    margin-top: 1.5em;
    margin-bottom: 2em;
    width: 150px;  
    // margin-left: 1em;
  }

  .locationAddressView {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-top: 1em;
    margin-bottom: 1em;
    width: 40%; 
  }

  .duoLiftRightPanelTopSection {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-top: 1em;
    margin-bottom: 1em;
    width: 100%;
  }

  .duoLiftRightPanelTabHeader {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-top: 1em;
    margin-left: 5px;
    margin-bottom: 2px;
    width: 100%;
  }

  .rightCardStatusTab {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
  }


  .rightCardStatusElements {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%; 
    margin-top: 20px;
  }

  .rightCardStopElements {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%; 
    margin-top: 20px;
    justify-content: space-between;
    overflow: hidden;
    box-shadow: 4px 4px 4px 4px rgba(0,0,0,0.3);
    // padding: 2px;
    margin-top: 10px;
  }

  .rightCardStatusRowElements {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    height: 100%; 
  }

  .rightCardStatusRowStopElements {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 100%; 
    overflow: hidden;
    box-shadow: 4px 4px 4px 4px rgba(0,0,0,0.3);
    // padding: 2px;
    margin-top: 10px;
  }

  .rightCardStatusColumnElements {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 100%; 
  }

  .rightCardAlertsTab {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
  }

  .rightCardStopsTab {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
  }

   .serviceTask{
    display: flex;
    justify-content: space-between;
    width: 100%;
   }
   .textAbsoluteCenter{
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;

   }

.fleetComparisionChart {
    display: flex;
    height: 50%;
    width: 100%;
}
.form {
    position: relative;
    text-align: center;
  }
.maintenanceLink {
    color:grey;
    background-color: white;
    border: none;
}
.alertLink {
    color:black;
    text-decoration: none;
}
.navMenu{
    margin: 1.0em .25em 0.5em 0.0em;
    padding: 0.5em 0.5em 0.5em 0.5em;
    text-align: center;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    border: .1px solid black;
    border-radius: .33em;
    height: auto !important;
    overflow: auto;
    margin: 1.0em 1.5em 0.5em 0.0em;
    padding: 0px;
    min-height: 15em;
    position: absolute;
    min-width: 20em;
    z-index: 1;
    background-color: white;

}
.navButton > span{
    font-size: 24px;
}

ul {
    padding: 0 0 0 0;
    margin: 0 0 0 0;
    list-style: none;
}

li {
    margin: 0 0 0 0;
}


@media (min-width:1401px){
    .duolift-device-detail-service-group{
        @include duolift-device-detail-card;
        flex-basis:100%;
        display: flex;
        margin-right: 0px;
    }
    .duolift-device-service-detail-card:not(:nth-last-child(-n+2)):after {
        content: "";
        display: block;
        position: absolute;
        background: black;
        width: 1px;
        height: 100%;
        right: 0;
        top: 50%;
    }
    // :nth-child(2)
    // .duolift-device-service-detail-card:not(:last-child):after {
    //     content: "";
    //     display: block;
    //     position: absolute;
    //     background: black;
    //     width: 1px;
    //     height: 100%;
    //     right: 0;
    //     top: 50%;
    // }
    .serviceItemCenter{
        top:90px
    }
}

.infomsg {
    display:none;
  }

@media (max-width:1400px){
    .flexContainer-detail > * {
        flex-basis: 50%;
    
    }
    .header-icon-menu > img{
        width: 9em;
        //margin-left: 25%;
    
}
    .header-icon-menu > a > span {
        font-size:18px;
    }
    .duolift-device-detail-service-group{
        flex-basis:100%;
    }
    .duolift-device-detail-card{
        margin-right: 0px;
    }
    .duolift-device-service-detail-card{
        @include duolift-device-detail-card;
        margin-right: 0px;
    
    }
    .detail-card-header-inline{
        border-bottom-right-radius: 0.33em;
        border-bottom-left-radius: 0.33em;

    }
    .serviceItemCenter{
        top:50%
    }
    // .duoLiftList{

    //     max-height: inherit;
     
    // }
    
}
@media(max-width:1340px){
    .dashboard-left-panel{
        flex-basis: 100%;
    }
     .duoLiftList{

        max-height: inherit;
        position: relative;
        margin-right: 0px;
     
    }
}

@media (max-width:840px){
    .dialog-container{
        width:100%;
        margin-left: 0px;
    }
    .dashboard-card{
        margin-right: 0px !important;
    }
    .flexContainer-detail > * {
        flex-basis: 100%;
    
    }
    .duolift-device-detail-service-group{
        flex-basis:100%;
    }
  
    .dashboard{
        display: flex; justify-content: space-between;     flex-wrap: wrap;        flex-direction: column;
    }
    .duolift-device-detail-card{
        margin-right: 0px;
    }
    .header-fullscreen-menu{
        display: grid;
    }
    .header-fullscreen-menu{
        flex-flow: column;
    }
    .header-icon-menu > img{
        width: 5em;
        //margin-left: 25%;
    
    }
    .header-icon-menu > a > span {
        font-size:18px;
    }
    .duoLiftList{

        max-height: inherit;
        position: relative;
        margin-right: 0px;

    }
    .popup-wrapper{
        width: 100%;
        position: relative
    }
}

.color-grey{
    color: #b3b3b3 !important;
}

.app-name-font {
    font-weight: bolder !important
}

.login-page {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    z-index: 10000;
    background: rgb(68, 177, 208);

    .login-page-image {
        background: rgb(68, 177, 208);
        display: grid;
        place-content: center;
        padding: 40px;
        width: 400px;
    }

    .forgot-password {
        justify-content: space-between;
        flex-direction: column;
        margin-top: 40px;
    }

    .login-user-input-fields {
        display: flex; 
        justify-content: flex-start;
        flex-direction: column;
        align-items: flex-start
    }
  
    .login-panel {
      width: 330px;
      height: 350px;
      max-width: 100%;
      text-align: center;
  
      .logo {
        width: 70%;
        display: block;
        margin: auto;
        margin-top: 44px;
        margin-bottom: 20px;
      }

      .keep-login {
        padding-left: 65px;
        display: flex;
      }
  
      h5 {
        font-weight: 900;
        font-size: 36px;
        line-height: 42px;
      }
  
      .form {
        padding-left: 65px;
        padding-right: 65px;
        margin-top: 50px;
  
        .error-message {
          color: red;
        }
  
        .MuiTextField-root {
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          width: 100%;
          margin-bottom: 10px;
        }
  
       
      }
    }
  }

    .login-page-Button {
        .MuiButtonBase-root {
            background: #00b0f0;
            width: 100%;
         height: 45px;
         color: white;
          margin-top: 32px;
       }
    }
   
  @font-face {
    font-family: 'password';
    font-style: normal;
    font-weight: 400;
    src: url('assets/password.ttf');
  }

  #passwordField {
    font-family: 'password';
  }