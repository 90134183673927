body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #44B1D0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Removes all borders from mui */
fieldset {
  border: none !important;
  outline: none !important;
}

/* Amplify UI */
.amplify-button[data-variation="primary"] {
  background: #44B1D0;
}

.amplify-button[data-variation="primary"]:hover {
  background: #44B1D0;
}

.amplify-button[data-variation="link"]:hover {
  background: #e9f9fc;
}

.amplify-field-group__field-wrapper {
  text-align: left !important;
}

.amplify-input.amplify-field-group__control {
  border: 1px solid #d8dae5;
}

.amplify-field-group__outer-end {
  border: 1px solid #d8dae5;
}

.amplify-field-group__outer-start .amplify-field-group__control,
.amplify-field-group__outer-end .amplify-field-group__control {
  border: 1px solid #d8dae5;
}

[data-amplify-authenticator] {
  --amplify-components-authenticator-container-width-max: 400px;
  --amplify-components-authenticator-router-border-color: transparent;
  --amplify-components-authenticator-router-box-shadow: none;
}

[data-amplify-footer] {
  padding-bottom: 10px !important;
  width: 47% !important;
}

.loginHome {
  background: #44B1D0;
}

.loginPage {
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  border-radius: 10px;
  padding: 20px;
}

.sso-cont {
  display: block;
  position: absolute;
  width: 44%;
  right: 20px;
  bottom: 30px;
}

.SSO-button {
  background: none !important;
  border: none !important;
  font-weight: normal !important;
  font-size: 14px !important;
  color: #047d95 !important;
  padding: 5px 10px !important;
  text-transform: none !important;
  border-radius: 5px !important;
  font-family: inherit !important;
}

.SSO-button:hover {
  background-color: #e9f9fc !important;
  color: #005566 !important;
}
input::-ms-reveal{
  display:none;
}